
import { courseSeriesList } from '@/states/couseSeries'
import { fromNow } from '@/utils/format'

export default {
  setup: () => {
    return {
      courseSeriesList,
      fromNow
    }
  }
}
